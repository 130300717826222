import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { MemberLookupRoutingModule, routedComponents } from './member-lookup-routing.module';
import { MemberSearchComponent } from './member-search/member-search.component';
import { MemberComponent } from './member/member.component';
import { MemberResultsComponent } from './member-results/member-results.component';
import { AppComponentsModule } from '../app-components/app-components.module';
import { MemberDemographicsComponent } from './member-demographics/member-demographics.component';
import { MemberDetailsComponent } from './member-details/member-details.component';
// tslint:disable-next-line: max-line-length
import { MemberDetailsEligibilityComponent } from './member-details/member-detail-tabs/member-details-eligibility/member-details-eligibility.component';
// tslint:disable-next-line: max-line-length
import { MemberEligibilityHistoryComponent } from './member-details/member-detail-tabs/member-details-eligibility/member-eligibility-history/member-eligibility-history.component';
import { MemberReferralsComponent } from './member-details/member-detail-tabs/member-referrals/member-referrals.component';
import { PortalUIComponentsModule } from '@caloptima/portal-ui-components';
import { MemberMedicationsComponent } from './member-details/member-detail-tabs/member-medications/member-medications.component';
import { MemberClaimsComponent } from './member-details/member-detail-tabs/member-claims/member-claims.component';
import { MemberLabsComponent } from './member-details/member-detail-tabs/member-labs/member-labs.component';
import { MemberConditionsComponent } from './member-details/member-detail-tabs/member-conditions/member-conditions.component';
import { MemberCustomMessagesNonpcpComponent } from './member-details/member-detail-tabs/member-custom-messages/member-custom-messages-nonpcp/member-custom-messages-nonpcp.component';
import { MemberHifComponent } from './member-details/member-detail-tabs/member-hif/member-hif.component';

@NgModule({
  declarations: [
    routedComponents,
    MemberSearchComponent,
    MemberComponent,
    MemberResultsComponent,
    MemberDemographicsComponent,
    MemberDetailsComponent,
    MemberDetailsEligibilityComponent,
    MemberEligibilityHistoryComponent,
    MemberReferralsComponent,
    MemberMedicationsComponent,
    MemberClaimsComponent,
    MemberLabsComponent,
    MemberConditionsComponent,
    MemberCustomMessagesNonpcpComponent,
    MemberHifComponent
    // CityLookupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MemberLookupRoutingModule,
    AppComponentsModule,
    PortalUIComponentsModule,
    // primeNng
    TabViewModule,
    TableModule,
    TooltipModule,
    DataViewModule,
    DropdownModule,
    DialogModule,
    ToastModule,
    TableModule
  ],
  providers: [
    DatePipe
  ]
})

export class MemberLookupModule { }
