<div class="member-details-container">
  <p-tabView (onChange)="handleChange($event)" styleClass="member-details-tabview-styles">
      <p-tabPanel [header]="getTabsName(0)">
        <ng-template pTemplate="content">
          <app-member-details-eligibility *ngIf= "!isLoading" [memberEligibility]="memberEligibility" [memberEligibilityList]="memberEligibilityList" [totalHistories] = "totalHistories"></app-member-details-eligibility>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewConditionHistory" [header]="getTabsName(1)">
          <ng-template pTemplate="content">
            <app-member-conditions [tabName]="getTabsName(1)" [isLoading]="isLoading" (conditionsChange)="conditionsChange($event)"></app-member-conditions>
          </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewClaims" [header]="getTabsName(2)">
          <ng-template pTemplate="content">
            <app-member-claims [tabName]="getTabsName(2)"  [isLoading]="isLoading" (claimsChange)="claimsChange($event)"></app-member-claims>
          </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewReferrals" [header]="getTabsName(3)" >
          <ng-template pTemplate="content">
            <app-member-referrals [tabName]="getTabsName(3)" [isLoading]="isLoading" (referralsChange)="referralsChange($event)"></app-member-referrals>
          </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewLabs" [header]="getTabsName(4)">
          <ng-template pTemplate="content">
            <app-member-labs [tabName]="getTabsName(4)"  [isLoading]="isLoading" (labsChange)="labsChange($event)"></app-member-labs>
          </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="canViewPharmacy" [header]="getTabsName(5)">
          <ng-template pTemplate="content">
            <app-member-medications  [tabName]="getTabsName(5)" [isLoading]="isLoading" (medicationsChange)="medicationsChange($event)"></app-member-medications>
          </ng-template>
      </p-tabPanel>
      <p-tabPanel *ngIf="isUserAssocToMemberPcp" [header]="getTabsName(6)">
        <ng-template pTemplate="content">
          <app-member-hif (hifChange)="hifChange($event)"></app-member-hif>
        </ng-template>
    </p-tabPanel>      
  </p-tabView>
</div>
