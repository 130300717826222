import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MemberInfoService } from '../../services/member-info.service';
import { Member } from '../../services/models/member';
import { MemberStatusMap } from '../../services/models/member-status-map';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-member-results',
  templateUrl: './member-results.component.html',
  styleUrls: ['./member-results.component.scss']
})
export class MemberResultsComponent implements OnInit, OnDestroy {
  public memberList: Member[] = [];
  public emptyListMessage: string = null;
  public memberListSubscription$: Subscription;
  public selectedmember: Member;
  public showMessage = true;
  // sort fields
  public sortColumn = 'name';
  public sortAscending = true;
  public totalMembers = 0;
  public sortOptions: SelectItem[];
  public sortField: string;
  public sortOrder: number;
  public sortKey: SelectItem;
  public gender: string;
  public today = new Date().toISOString();

  @Output() memberSelected = new EventEmitter<boolean>();

  constructor(private memberService: MemberInfoService) { }

  ngOnInit() {
    this.sortOptions = [
      {label: 'Eligibility Status', value: 'eligibilityStatus'},
      {label: 'Effective Date', value: 'healthNetworkPlanEffectiveDate'},
      {label: 'Gender', value: 'gender'},
      {label: 'Line of Business', value: 'lineOfBusiness'},
      {label: 'Health Network', value: 'healthNetworkPlanCode'},
      {label: 'PCP', value: 'pcp'},
    ];
    this.sortKey = this.sortOptions[0];

    this.memberListSubscription$ = this.memberService.memberListChanged$.subscribe(
      data => {
        if (data) {
          this.sortField = undefined;
          this.memberList = data.members;
          this.totalMembers = this.memberList.length;
          this.emptyListMessage = data.statusMessage;
          this.showMessage = this.totalMembers <= 0;
        }
        return data;
      },
      error => {
        this.emptyListMessage = 'Too many members found.  Please refine your search!';
        // log error
      });
  }

  ngOnDestroy() {
    if (this.memberListSubscription$) {
      this.memberListSubscription$.unsubscribe();
    }
  }

  //Default sorting method
public onSortInit(){
  if(this.sortField == undefined){
  this.sortField = 'eligibilityStatus';
  this.sortOrder = -1;}
}

public onSortChange(event) {
  const value = event.value;
  if (value === 'eligibilityStatus') {
    this.sortOrder = -1;
  }
  else  {
    this.sortOrder = 1;
  }
  this.sortField = value;
}


  public sortClass(column: string): string {
    let cls: string;
    if (column === this.sortColumn) {
      cls = this.sortAscending ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
    }
    else {
      cls = 'fa fa-chevron-up';
    }
    return cls;
  }


  private sortMembers(sortColumn: string, sortAscending: boolean): void {

  }

  public onSort(event: any): void {

  }

    // Get the class(es) to display for the status icon
    public mapStatusToIcon(member: Member): string {
      return MemberStatusMap.mapStatustoIcon(member.eligibilityStatus);
    }

  // Get the string to display for the tooltip (title) for the user hovers over the status icon
    public mapStatusToTooltip(member: Member): string {
    return MemberStatusMap.mapStatusToSelectItem(member.eligibilityStatus).label;
    }

  public mapStatusToTitle(member: Member): string {
    return MemberStatusMap.mapStatusToSelectItem(member.eligibilityStatus).title;
  }

  public mapStatusToColor(member: Member): string {
    return MemberStatusMap.mapStatusToSelectItem(member.eligibilityStatus).styleClass;
  }

  public onDetailClicked(event, row: Member) {
      // TODO: for next story set output to selected member object
      //      parent call service for detail object. Service sets
      //      behavior subject, fires update. change triggers
      //      facesheet to show.
      this.memberService.setSelectMember(row);
      let result = true;
      this.memberSelected.emit(result);
  }
}
