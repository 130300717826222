<div class="member-results-container">
  <div class="member-results-grid" *ngIf="!showMessage">
    <p-dataView [value]="memberList" [sortField]="sortField" [paginatorDropdownScrollHeight]="400" [sortOrder]="sortOrder" (onSort)="onSortInit()">
      <ng-template pTemplate="header">
        <div class="p-helper-clearfix">
          <div class="p-g">
            <div class="p-g-8 p-md-2 p-sm-1"></div>
            <div class="sorting-option p-g-4 p-md-10 p-sm-11">
              <span class="sort-text">Sort by: </span>
              <p-dropdown *ngIf="sortOptions.length > 0" optionLabel="label" [options]="sortOptions" [(ngModel)]="sortKey"
                (onChange)="onSortChange($event)"></p-dropdown>
            </div>
          </div>
        </div>
      </ng-template>      
      <ng-template pTemplate="list" let-memberList>
        <div class="col-12" *ngFor="let row of memberList; let first = first">
          <div class="member-result-container-grid">
            <div class="member-info-status" >
              <div [ngClass]="mapStatusToColor(row)" [innerText]="mapStatusToTitle(row)" ></div> 
            </div>
            <div class="icon-user-eligibility">
              <i class="user-eligibility-icon" [ngClass]="mapStatusToIcon(row)" [title]="mapStatusToTooltip(row)"></i>
            </div>
            <div class="member-info-name">
              {{row.firstName}} {{row.lastName}}
            </div>
            <div class="member-info-midl">
              CIN:
            </div>
            <div class="member-info-mbil">
              MBI:
            </div>
            <div class="member-info-midv">
              {{row.cin}}
            </div>
            <div class="member-info-mbiv">
              {{row.mbi || 'None'}}
            </div>
            <div class="member-header-dobl">
              Date of birth:
            </div>
            <div class="member-header-genl">
              Gender:
            </div>
            <div class="member-header-lobl">
              Line of business:
            </div>
            <div class="member-header-hntl">
              Health network:
            </div>
            <div class="member-header-pcpl">
              PCP Name:
            </div>
            <div class="member-header-edtl">
              Effective date:
            </div>
            <div class="member-detail-dobv">
              {{row.dateOfBirth | date: 'MM/dd/yyyy'}}<br>
            </div>
            <div class="member-detail-genv">
              {{row.genderDisplay}}<br>
            </div>
            <div class="member-detail-lobv">
              {{row.lineOfBusiness}}<br>
            </div>
            <div class="member-detail-hntv ">
              {{row.healthNetworkPlanDesc}}<br>
            </div>
            <div class="member-detail-pcpv">
              {{row.pcpInfo.pcpName || 'None'}}<br>
            </div>
            <div class="member-detail-edtv" *ngIf="row.healthNetworkPlanTermDate > this.today">
              {{row.healthNetworkPlanEffectiveDate| date: 'MM/dd/yyyy'}} - Current
            </div>
            <div class="member-detail-edtv" *ngIf="!(row.healthNetworkPlanTermDate > this.today)">
              {{row.healthNetworkPlanEffectiveDate| date: 'MM/dd/yyyy'}} -
              {{row.healthNetworkPlanTermDate| date: 'MM/dd/yyyy'}}
            </div>
            <div class="open-icon ">
              <button pButton type="button" class=" detail-button" title="Open Member Facesheet" (click)="onDetailClicked($event, row)">
                <i class="fa fa-external-link"></i>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
  <div *ngIf="showMessage" class="member-result-message-container">
    <div class="message-header"></div>
    <div class="member-results-grid-message">
      {{ emptyListMessage }}
    </div>
  </div>
  <div class="member-results-footer">
    <div class="result-number">
      {{totalMembers}} results
    </div>
  </div>
</div>



