import { Component, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { Subscription, of, Observable } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { UiUtility } from '../../utils/ui-utility';
import { MemberEligibility } from '../../services/models/member-eligibility';
import { Member } from '../../services/models/member';
import { MemberInfoService } from '../../services/member-info.service';
import { SessionService } from '../../services/session.service';
import { ModulePermission } from '../../services/models/module-permission';
import { MemberReferral } from '../../services/models/member-referral';
import { Claim } from '../../services/models/claim';
import { BusyService } from '../../services/busy.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  public memberEligibility: MemberEligibility = new MemberEligibility();
  public memberEligibilityList: MemberEligibility[] = [];
  public selectedMember$: Subscription;
  public permissions$: Subscription;
  public member: Member;
  public memberDateAndAge: string;
  public totalHistories = 0;
  public canViewClaims = false;
  public canViewReferrals = false;
  public canViewLabs = false;
  public canViewPharmacy = false;
  public canViewConditionHistory = false;
  public memberReferralList: MemberReferral[] = [];
  public totalReferrals: number;
  public memberClaimList: Claim[] = [];
  public totalClaims: number;
  public isLoading: boolean;
  public index = 0;
  public isConditionLoading: boolean;
  public isClaimLoading: boolean;
  public isReferralLoading: boolean;
  public isLabLoading: boolean;
  public isMedicationLoading: boolean;
  public isHifLoading: boolean;
  public isUserAssocToMemberPcp = false;
  private responsivePanelSubscription$: Subscription;

  private tabs: any = [
    { index: 0, name: 'Eligibility' },
    { index: 1, name: 'Condition History' },
    { index: 2, name: 'Claims History' },
    { index: 3, name: 'Referrals' },
    { index: 4, name: 'Labs' },
    { index: 5, name: 'Medications' },
    { index: 6, name: 'Health Information' }
  ];

  @Output() isLoadingChange = new EventEmitter();

  constructor(
    private memberService: MemberInfoService,
    private sessionService: SessionService,
    private busy: BusyService,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {

    this.permissions$ = this.sessionService.permissionsChanged()
      .pipe(
        map( res => {
          this.canViewClaims = this.sessionService.hasPermission(['PRVCLR']);
          this.canViewReferrals = this.sessionService.hasPermission(['PRVRER', 'PRVREM']);
          this.canViewLabs = this.sessionService.hasPermission(['PRVLAR']);
          this.canViewPharmacy = this.sessionService.hasPermission(['PRVPHR']);
          this.canViewConditionHistory = this.sessionService.hasPermission(['PRVCHR']);

          return res;
        })
      ).subscribe();
    // TODO: add pipe to switch map from member eligibility to selected member
    //       call member info service to get eligibility data
    this.selectedMember$ = this.memberService.selectedMemberChanged$.subscribe(data => {
      this.isBusy(true);
      this.resetLoading();
      this.member = data;
      this.isUserAssocToMemberPcp = this.member.isUserAssocToMemberPcp;
      this.memberDateAndAge = this.memberService.getMemberDateAndAge(this.member.dateOfBirth);
      this.memberService.getMemberEligibility(this.member.memeCk).subscribe (result => {
        if (this.index === 0 ){
          this.isBusy(false);
        }
        this.memberEligibility = result;
      });
      this.memberService.getMemberEligibilityHistory(this.member.memeCk).subscribe(history => {
        this.memberEligibilityList = history;
        this.totalHistories = this.memberEligibilityList.length;
      });
    });
  }

  ngOnDestroy() {
    if (this.selectedMember$) {
      this.selectedMember$.unsubscribe();
    }
    if (this.permissions$) {
      this.permissions$.unsubscribe();
    }
    if (this.responsivePanelSubscription$) {
      this.responsivePanelSubscription$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.setMemberDetailTabWidth();
  }

  public resetLoading() {
    this.isReferralLoading = true;
    this.isClaimLoading = true;
    this.isConditionLoading = true;
    this.isLabLoading = true;
    this.isMedicationLoading = true;
    this.isHifLoading = true;
  }

  public handleChange(e) {
    this.index = this.getTabsIndex(e.originalEvent.target.innerText);

    // handle data latency for different apis
    if (this.index === 1 && this.isConditionLoading === true ) {
       this.isBusy(true);
    }
    if (this.index === 2 && this.isClaimLoading === true ) {
       this.isBusy(true);
    }
    if (this.index === 3 && this.isReferralLoading === true ) {
       this.isBusy(true);
    }
    if (this.index === 4 && this.isLabLoading === true ) {
       this.isBusy(true);
    }
    if (this.index === 5 && this.isMedicationLoading === true ) {
       this.isBusy(true);
    }
    if (this.index === 6 && this.isHifLoading === true ) {
       this.isBusy(true);
    }
}

  private isBusy(busy: boolean): void {
    this.isLoading = busy;
    this.busy.emit(busy);
    this.isLoadingChange.emit(this.isLoading);
  }

  public conditionsChange(event){
    if (event === true) {
      this.isConditionLoading = false;
      if (this.index === 1) {
        this.isBusy(false);
      }
    }
  }

  public claimsChange(event){
    if (event === true) {
      this.isClaimLoading = false;
      if (this.index === 2) {
        this.isBusy(false);
      }
    }
  }

  public referralsChange(event){
    if (event === true) {
      this.isReferralLoading = false;
      if (this.index === 3) {
         this.isBusy(false);
      }
    }
  }

  public labsChange(event){
    if (event === true) {
      this.isLabLoading = false;
      if (this.index === 4) {
        this.isBusy(false);
      }
    }
  }

  public medicationsChange(event){
    if (event === true) {
      this.isMedicationLoading = false;
      if (this.index === 5) {
        this.isBusy(false);
      }
    }
  }

  public hifChange(event){
    if (event === true) {
      this.isHifLoading = false;
      if (this.index === 6) {
        this.isBusy(false);
      }
    }
  }

  public getTabsName(index: number) {
    const currentTab = this.tabs.find((tab: any) => tab.index === index);
    return currentTab ? currentTab.name : '';
  }

  private getTabsIndex(name: string) {
    const currentTab = this.tabs.find((tab: any) => tab.name === name);
    return currentTab ? currentTab.index : 0;
  }

  private setMemberDetailTabWidth() {
    this.responsivePanelSubscription$ = this.breakpointObserver
    .observe(['(max-width: 1350px)', '(max-width: 1200px)', '(max-width: 1050px)', '(max-width: 900px)'])
    .subscribe((state: BreakpointState) => {
      const tabView = window.document.querySelector('div.member-details-tabview-styles  ul.p-tabview-nav');
      if (state.breakpoints['(max-width: 900px)']) {
        UiUtility.SetTabsWidthDynamically(tabView, 50);
      } else if (state.breakpoints['(max-width: 1050px)']) {
        UiUtility.SetTabsWidthDynamically(tabView, 33);
      } else if (state.breakpoints['(max-width: 1200px)']) {
        UiUtility.SetTabsWidthDynamically(tabView, 25);
      } else if (state.breakpoints['(max-width: 1350px)']) {
        UiUtility.SetTabsWidthDynamically(tabView, 20);
      } else {
        UiUtility.SetTabsWidthDynamically(tabView);
      }
    });
  }
}
